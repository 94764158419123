@import "../../style/_settings.scss";

.emergency {
  margin: auto;
  max-width: 400px;
  padding-bottom: 2em;

  h1 {
    font-size: 1.4em;
    padding: 3px 20px 6px;
    background-color: transparentize($color: #fff, $amount: 0.85);
    text-align: center;
  }

  .content {
    margin: 1em 20px;
  }

  h2 {
    font-weight: bold;
    margin-top: 1em;
  }

  h3 {
    font-weight: bold;
    float: left;
    clear: left;
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 14px;

    &:after {
      content: ": ";
    }
  }

  h3 + p {
    margin-top: 10px;
    float: left;
    clear: right;
    opacity: 0.7;
  }
}

.unimportant {
  font-weight: normal;
  opacity: 0.7;
  font-size: 13px;
}
