@import "./_settings.scss";

html {
  font-family: "Open Sans", serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings: normal;
  font-variation-settings: "wdth" 100;

  background-color: $color--background !important;

  &:before {
    content: "";
    z-index: -1;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(
      to bottom,
      $color--background 0%,
      $color--background-alt 100%
    );
  }
}

body {
  color: #fff;
  overflow-y: scroll;
  -webkit-font-smoothing: antialiased;

  line-height: normal;
  -webkit-overflow-scrolling: touch;
  -webkit-text-size-adjust: 100%;
  max-width: 812px; // iPhone X
  margin: 0 auto;

  &.has-popup {
    overflow: hidden;
  }
}

.ptr--ptr {
  font-size: 13px;

  .ptr--text,
  .ptr--icon {
    color: currentColor;
  }
}

strong {
  font-weight: bold;
}

em {
  font-style: italic;
}

a {
  color: $color--link;
  text-decoration: none;
  transition: color 0.3s, text-decoration-color 0.3s, background-color 0.3s;

  color: $color--link;
  text-decoration: underline;
  text-decoration-color: transparentize($color--link, 1);

  &:hover {
    color: $color--link;
    text-decoration: underline;
    text-decoration-color: $color--link;
  }
}
