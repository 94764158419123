@import "../../style/_settings.scss";

// Grid size at 812 viewport = 812 / 450

.grid {
  display: grid;

  grid-template-columns: 1fr 1fr 1fr;

  > li {
    &:nth-child(4) {
      display: none;
    }

    &.wsdot,
    &.youtube {
      > button,
      > div {
        position: relative;
        overflow: hidden;
        height: 0;
        padding-top: calc(9 / 16) * 100%;

        background: linear-gradient(
          150deg,
          transparentize(#000, 0.9) 0%,
          transparentize(#000, 0.5) 100%
        );
      }
    }

    &.wsdot > button {
      img {
        position: absolute;
        max-width: none;
        top: -35%;

        // Remove black border
        left: -0.7%;
        width: 101.4%;
      }
    }

    &.youtube {
      > div {
        iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: transparent;
        }
      }
    }
  }

  @media (max-width: 812px) and (max-aspect-ratio: 812 / 586),
    (min-width: 812px) and (min-height: 586px) {
    grid-template-columns: 3fr 2fr;

    > li {
      &:nth-child(4) {
        display: none;
      }

      &.wsdot {
        grid-row-start: 1;
        grid-row-end: 4;

        > button {
          //padding-top: (263 / 320) * 100%; // Normaly 320x283 but crops bottom
          height: 100%;
          padding-top: 0;

          img {
            // Remove black border
            top: -0.7%;
          }
        }
      }
    }
  }

  @media (max-width: 812px) and (max-aspect-ratio: 812 / 766),
    (min-width: 812px) and (min-height: 766px) {
    grid-template-columns: 2.045fr 1fr;

    > li {
      &:nth-child(4) {
        display: block;
      }
    }
  }
}

.popup {
  min-height: 100%;
  padding-top: 10px;

  > li {
    text-align: center;
    padding: 0 10px 10px;
    max-width: 600px;
    margin: 0 auto;

    &.wsdot > div {
      position: relative;
      overflow: hidden;
      height: 0;
      padding-top: calc(263 / 320) * 100%; // Normaly 320x283 but crops bottom
      background-color: #000;

      img {
        position: absolute;
        max-width: none;

        // Remove black border
        top: -0.7%;
        left: -0.7%;
        width: 101.4%;
      }
    }

    &.youtube > div {
      position: relative;
      overflow: hidden;
      height: 0;
      padding-top: calc(9 / 16) * 100%;
      background-color: #000;

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}

.carouselDots {
  position: absolute;
  bottom: 4px;
  z-index: 10;
  display: flex;
  width: 100%;
  justify-content: center;

  li {
    width: 7px;
    height: 7px;
    margin: 2px;
    border-radius: 100%;
    background-color: #fff;
    border: 1px solid transparentize(#000000, 0.4);
    opacity: 0.5;

    &.current {
      opacity: 0.8;
      border-radius: 0;
    }
  }
}
