@import "../../style/_settings.scss";

.wsdot {
  padding: 10px 10px;

  > div + div {
    margin-top: 10px;
  }

  > div .value {
    padding-left: 0.4em;
  }

  > div:before {
    opacity: 0.7;
    font-size: 11px;
  }

  .temp {
    &:before {
      content: "Temp";
    }

    .value:after {
      content: "°";
    }
  }

  .eastbound,
  .westbound,
  .roadCondition {
    &.closed:before {
      color: #e80645;
      opacity: 1;
      font-weight: bold;
    }
  }

  .eastbound:before {
    content: "Eastbound";
  }

  .westbound:before {
    content: "Westbound";
  }

  .roadCondition:before {
    content: "Conditions";
  }

  .weather:before {
    content: "Weather";
  }

  .date {
    text-align: center;
    font-size: 11px;
    opacity: 0.4;
  }
}
