$debug: false;
$ems: true;
$rem-px: 16px;
$font-size: 18px;

$opacity--text-dim: 0.8;

//$color--icon: #053e7a;

// Colors
$color--background: #053e7a;
$color--background-alt: #011020;
$color--text: #fff;
$color--text-dim: transparentize($color--text, (1 - $opacity--text-dim));
$color--link: #6bf9ff;

$color--light: transparentize($color--text, 0.94);
$color--light-line: transparentize($color--text, 0.9);

// Sizes
$max-width: 1024px;
$max-width-text: 800px;
$font-size--header: 20px;
$side-padding: 5%;

@function strip-units($value) {
  @return $value / ($value * 0 + 1);
}

// Converts PX or % to EM
@function em($value, $base: $rem-px) {
  @if unit($base) != "px" {
    @error "$base units should be in pixels";
  } @else if unit($value) == "px" {
    @if $ems == true {
      @return strip-units($value) / strip-units($base) + 0em;
    } @else {
      @return $value;
    }
  } @else if unit($value) == "%" {
    @return strip-units($value) / 100 + 0em;
  } @else {
    @error "em(): $value units are required and must be px or %";
  }
}

// Converts PX or % to REM
@function rem($value) {
  @if unit($value) == "px" {
    @if $ems == true {
      @return strip-units($value) / strip-units($rem-px) + 0rem;
    } @else {
      @return $value;
    }
  } @else if unit($value) == "%" {
    @return strip-units($value) / 100 + 0rem;
  } @else {
    @error "rem(): $value units are required and must be px or %";
  }
}

// Converts EM, REM, or % to PX
@function px($value, $base: $rem-px) {
  @if unit($base) != "px" {
    @error "$base units should be in pixels";
  } @else if unit($value) == "em" {
    @return ceil(strip-units($base) * strip-units($value)) + 0px;
  } @else if unit($value) == "rem" {
    @if $base != $rem-px {
      @error "$base cannot be overridden when $value is rem units";
    }
    @return ceil(strip-units($rem-px) * strip-units($value)) + 0px;
  } @else if unit($value) == "%" {
    @return ceil(strip-units($base) * strip-units($value) * 0.01) + 0px;
  } @else {
    @error "px(): $value units are required and must be em, rem, or %";
  }
}
