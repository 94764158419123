@import "../../style/_settings.scss";

.lifts {
  max-width: 600px;
  margin: auto;
  padding-bottom: 2em;

  .content {
    display: flex;
    min-height: 80vh;
  }

  .loading {
    margin: auto;
  }

  h1,
  h2 {
    font-size: 1.4em;
    padding: 3px 20px 6px;
    background-color: transparentize($color: #fff, $amount: 0.85);
  }

  ul {
    width: 100%;

    ul {
      width: auto;
      margin: 10px 15px 15px;

      li {
        > div {
          display: grid;
          grid-template-columns: 36px 2fr 1fr;
          align-items: center;

          .icon {
            position: relative;
            top: 4px;
          }

          h3 {
            margin-left: 7px;
            font-weight: 500;
          }

          .time {
            opacity: 0.7;
            font-size: 14px;
            text-align: right;
          }
        }

        svg {
          color: #e8ae44;
        }

        &.open {
          svg {
            color: #51da72;
          }
        }

        &.closed {
          svg {
            color: #e24c7b;
          }
        }

        p {
          text-transform: lowercase;
          margin: -4px 0 4px;
          margin-left: 43px;
          opacity: 0.7;

          &::first-letter {
            text-transform: uppercase;
          }
        }
      }
    }
  }

  .ampm {
    font-size: 10px;
    font-weight: normal;
    text-transform: uppercase;
    display: inline-block;
    margin-left: 1px;
  }

  footer {
    padding: 10px 15px 15px;
    text-align: center;
    border-top: 4px solid transparentize($color: #fff, $amount: 0.85);
  }
}
