@import "../../style/_settings.scss";

.calendar {
  max-width: 1000px;
  margin: auto;
  padding-bottom: 2em;

  h1 {
    font-size: 1.4em;
    padding: 3px 20px 6px;
    background-color: transparentize($color: #fff, $amount: 0.85);
    text-align: center;
  }

  .content {
    display: flex;
    min-height: 80vh;
  }

  .loading {
    margin: auto;
  }

  .fixedTable {
    overflow-x: auto;
    font-size: 14px;
    font-variation-settings: "wdth" 95;
    margin: 1em 16px 1em 13px;
    width: 100%;

    .ampm {
      font-size: 10px;
      font-weight: normal;
      text-transform: uppercase;
      display: inline-block;
      margin-left: 1px;
    }

    .nowrap {
      white-space: nowrap;
      display: inline-block;
    }

    table {
      width: 100%;

      thead {
        position: sticky;
        z-index: 2;
        top: 0;
        white-space: nowrap;

        th {
          text-align: center;
          font-size: 14px;
          font-weight: 500;
          font-variation-settings: "wdth" 90;
        }
      }

      tbody {
        tr:nth-child(even) td {
          background-color: transparentize($color: #fff, $amount: 0.9);
        }

        td {
          border: 2px solid $color--background-alt;
          background-color: transparentize($color: #fff, $amount: 0.85);

          &.closed {
            background-color: transparentize($color: #e24c7b, $amount: 0.4);
            text-align: center;
          }
        }

        th.sticky {
          vertical-align: middle;
          text-align: center;
          border: none;
          background: $color--background-alt;
          padding-left: 0;
          font-size: 14px;
          font-weight: 500;
          font-variation-settings: "wdth" 100;

          .day {
            font-size: 12px;
            text-transform: uppercase;
            font-variation-settings: "wdth" 90;
            font-weight: 600;
          }
        }
      }

      th,
      td {
        padding: 4px 5px;
        vertical-align: middle;

        &.sticky {
          z-index: 1;
          position: sticky;
          left: 0;
        }
      }
    }
  }

  footer {
    padding: 10px 15px 15px;
    text-align: center;
    border-top: 4px solid transparentize($color: #fff, $amount: 0.85);
  }
}
