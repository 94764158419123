@import "../../../style/_settings.scss";

.carouselDots {
  position: absolute;
  bottom: 4px;
  z-index: 10;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;

  li {
    width: 7px;
    height: 7px;
    margin: 0 2px;
    border-radius: 100%;
    border: 1px solid;
    background-color: #fff;
    box-shadow: 0 0 3px #000;
    opacity: 0.3;

    &.current {
      opacity: 0.9;
    }
  }
}
