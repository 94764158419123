@import "../../style/_settings.scss";

.loader {
  color: #fff;
  text-align: center;

  .dots {
    overflow: hidden;
    transform: translateZ(0);
    display: inline-flex;

    .dot,
    &::before,
    &::after {
      position: relative;
      top: -3em;
      display: block;
      border-radius: 50%;
      width: 1em;
      height: 1em;
      animation-fill-mode: both;
      animation: fadeInOut 2s infinite ease-in-out;
    }

    .dot {
      margin: 0 0.6em;
    }

    &::before,
    &::after {
      content: "";
    }

    &::before {
      animation-delay: -0.2s;
    }

    &::after {
      animation-delay: 0.2s;
    }
  }
}

@keyframes fadeInOut {
  0% {
    box-shadow: 0 3em 0 -0.5em currentColor;
    opacity: 0.5;
  }
  50% {
    box-shadow: 0 3em 0 0 currentColor;
    opacity: 1;
  }
  100% {
    box-shadow: 0 3em 0 -0.5em currentColor;
    opacity: 0.5;
  }
}
