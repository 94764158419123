@import "../../style/_settings.scss";

.linkList {
  padding: 18px 10px 38px;

  > li {
    max-width: 230px;
    margin: 0 auto;

    + li {
      margin-top: 14px;
    }
  }

  a {
    border-radius: 4px;
    padding: 4px 8px 6px;

    &:active {
      background-color: transparentize(#fff, 0.92);
    }
  }

  img {
    display: inline-block;
    margin-right: 6px;
    width: 24px;
    vertical-align: -6px;
  }
}
