@import "../../style/_settings.scss";

.skiareaInfo {
  padding: 10px 10px;
  background: transparentize(#fff, 0.92);
  background: linear-gradient(
    to bottom,
    transparentize(#fff, 0.94) 0%,
    transparentize(#fff, 0.9) 100%
  );

  .snowComments {
    margin-top: 16px;
    opacity: 0.7;
    white-space: pre-wrap;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    transition: opacity 0.3s;

    div,
    p,
    h2,
    h3,
    h4,
    h5,
    h6 {
      display: inline;
    }

    &.show {
      display: block;
      opacity: 1;

      div,
      p,
      h2,
      h3,
      h4,
      h5,
      h6 {
        display: block;
      }
    }

    b,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-weight: 600;
    }
  }

  .bcGates {
    display: flex;
    margin: 0 auto;
    justify-content: center;

    li {
      margin: 0 8px;
    }

    .label {
      opacity: 0.7;
      font-size: 11px;
    }

    .value {
      position: relative;
      display: inline-block;
      margin-left: 0.4em;
      width: 1em;
      height: 1em;
      border-radius: 100%;
      vertical-align: middle;
      background-color: transparentize(#fff, 0.8);
    }

    .open .value {
      background-color: #2dcc00;
    }

    .closed .value {
      background-color: #e80645;

      &:before,
      &:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%) rotate(45deg);
        transform-origin: center;
        width: 70%;
        height: 2px;
        background-color: #021b36;
      }

      &:after {
        transform: translateY(-50%) translateX(-50%) rotate(-45deg);
      }
    }
  }

  .date {
    margin-top: 16px;

    text-align: center;
    font-size: 11px;
    opacity: 0.4;
  }
}

.snowInfo,
.snowInfoLabels {
  display: grid;
  grid-template-columns: 0.3fr 1fr 1fr 1fr 1fr 1fr 1fr;
  text-align: center;

  h2 {
    writing-mode: vertical-rl;
    text-orientation: sideways;
    transform: rotate(180deg);
    text-align: left;
    font-weight: normal;
    opacity: 0.7;
    font-size: 11px;
  }

  .temp {
    > span:after {
      content: "°";
    }
  }

  .snow {
    > span:after {
      content: '"';
    }
  }
}

.snowInfo {
  margin-bottom: 14px;
}

.snowInfoLabels {
  margin-bottom: 6px;
  div {
    display: block;
    opacity: 0.7;
    font-size: 11px;
    margin-bottom: 1px;
  }
}
